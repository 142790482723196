import { AuthAction, setRedirectUrl } from "@reduxMain/actions/authActions";
import store from "@reduxMain/store";
import _ from "lodash";
import { MenuAction } from "@common/redux/actions/menuActions";
import { ORG_ROUTE_REGEX, UTM_SOURCE } from "@common/constants/constants";
import { normalizeQueryParam, setRedirectTo } from "@common/utils/common-utils";
import UserService from "@src/services/user.service";
import QueryBuilder from "@utils/Querybuilder";

const AuthGuard = async () => {
    const storeState = store.getState();
    const isEmailVerificationPath = window.location.pathname.startsWith("/auth/emailVerification");
    const isAuthPath = window.location.pathname.includes("auth");
    const currentURL = window.location.pathname + window.location.search + window.location.hash;

    const queryBuilder = new QueryBuilder(window.location.origin + currentURL);

    const queryParams = new URLSearchParams(window.location.search);
    const utmSource = queryParams.get("utm_source");
    const redirectUrlParam = normalizeQueryParam(queryParams.get("redirectUrl"));
    const redirectURL = redirectUrlParam || (currentURL.match(ORG_ROUTE_REGEX) ? currentURL : null);

    if (!utmSource && isAuthPath) queryBuilder.append("utm_source", UTM_SOURCE);

    // Added inside auth guard as it runs first and always
    const redirectTo = queryParams.get("redirectTo");
    if (redirectTo) {
        setRedirectTo(redirectTo);
    }

    // if we have user data in redux store then do not make the API call
    if (storeState["authenticationDetails"]["user"]) {
        // if user is logged in but opens a verification link then let it continue
        if (isEmailVerificationPath) {
            // store.dispatch({
            //     type: MenuAction.SetShowTopNavBar,
            //     payload: { showTopNavBar: false },
            // });
            return "";
        } else if (isAuthPath) {
            // redirect any routes to /auth/* to home if the user is logged in (client-side routing)
            return `/${queryBuilder.toString()}`;
        } else {
            return "";
        }
    } else {
        try {
            /**
             * Any `console.pixelbin.io` route having `?__token__=<<<bg.session cookie value>>>` as query params will make a call to `POST /token` API for verifying session.
             * This helps user to directly land on the page they want to access.
             * Built for simplifying payment flow for users coming from mobile app.
             * Context JIRA : PIXB-3757
             */
            const sessionToken = queryParams.get("__token__");
            if (sessionToken) {
                await UserService.verfiySessionToken(sessionToken);
            }
        } catch (error) {
            console.error(error);
        }
        try {
            const res = await UserService.getCurrentUserSession();
            store.dispatch({
                type: AuthAction.CheckUserSession,
                payload: {
                    isUserLoggedIn: true,
                    user: res.data.session.passport.user,
                },
            });

            // if user is logged in but opens a verification link then let it continue
            if (isEmailVerificationPath) {
                // store.dispatch({
                //     type: MenuAction.SetShowTopNavBar,
                //     payload: { showTopNavBar: false },
                // });
                return "";
            }

            // if user is logged in and redirect url is an absolute link, redirect user to redirect url
            if (redirectURL && /^https?:\/\//.test(redirectURL)) {
                const redirectUrlObj = new URL(redirectURL);
                if (redirectUrlObj.searchParams.has("pixelbinPricingFlow", "true")) {
                    redirectUrlObj.searchParams.delete("pixelbinPricingFlow");
                    redirectUrlObj.searchParams.append("__token__", res.data.bgSession);
                    window.open(redirectUrlObj.toString(), "_self");
                } else {
                    window.open(redirectURL, "_self");
                }
            } else {
                if (isAuthPath) {
                    if (
                        utmSource &&
                        !queryParams.get("redirectToPixelbinPropertyFlow") &&
                        !redirectURL
                    ) {
                        queryBuilder.append("redirectToFreePropertyFlow", "true");
                    }
                    return "/";
                }

                // if user is logged in then allow the requested path
                return "";
            }
        } catch (err) {
            // console.error(err);
            setRedirectUrl({ redirectUrl: redirectURL }, store.dispatch);

            if (isAuthPath) {
                if (queryParams.get("redirectToPixelbinPropertyFlow")) {
                    return `?${queryBuilder.toString()}`;
                }

                if (utmSource) {
                    if (!redirectURL) {
                        queryBuilder.append("redirectToPixelbinPropertyFlow", "true");
                    }
                    return {
                        location: window.location.pathname,
                        search: `?${queryBuilder.toString()}`,
                    };
                }

                return `?${queryBuilder.toString()}`;
            }
            let finalUrl = redirectURL
                ? `/auth/login?redirectUrl=${encodeURIComponent(
                      redirectURL,
                  )}&utm_source=${UTM_SOURCE}`
                : `/auth/login?utm_source=${UTM_SOURCE}`;
            return finalUrl;
        }
    }
};

export default AuthGuard;
