import SettingsMenuModal from "@common/components/GlobalNavBar/SettingsMenuModal";
import Icon from "@components/Icon";
import BDLogo from "@public/BDLogoWhite.jpg";
import environment from "@src/environment";
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import GenerationForm from "../GenerationForm/index";
import IconButton from "../IconButton";
import CreditLimitExceededPopup from "../Popup/CreditLimitExceededPopup";
import LogoutPopup from "../Popup/LogoutPopup";
import { EventEmitter } from "@events/eventEmitter";
import { MyGenerationsClick, ProfileClick } from "@events/events";

const Navbar = ({ user, organization }) => {
    const [settingsIsOpen, setSettingsIsOpen] = useState(false);
    const [logoutPopupIsOpen, setLogoutPopupIsOpen] = useState(false);
    const [isAtTop, setIsAtTop] = useState(true);
    const [isCreditLimitExceeded, setIsCreditLimitExceeded] = useState(false);
    const [formRect, setFormRect] = useState({});

    const formWrapperRef = useRef();
    const navbarWrapperRef = useRef();

    const toggleSettingsMenu = () => {
        EventEmitter.dispatch(
            ProfileClick,
            user && organization ? { email: user?.email, $set_once: { email: user?.email } } : {},
        );
        setSettingsIsOpen(!settingsIsOpen);
    };

    const handleScroll = () => {
        if (window.scrollY === 0) {
            setIsAtTop(true);
        } else {
            setIsAtTop(false);
        }

        setFormRect(formWrapperRef.current?.getBoundingClientRect() ?? {});

        if (navbarWrapperRef.current?.getBoundingClientRect()?.bottom < 0) {
            setSettingsIsOpen(false);
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <>
            <header
                className={`flex w-full justify-center items-center top-0 sticky z-50 ${
                    user && organization ? "" : "md:w-1/2"
                }`}
            >
                <div
                    className={`flex flex-col w-full min-h-[66px] ${
                        user && organization ? "md:min-h-[188px]" : ""
                    } transition-colors duration-500 ease-in-out ${
                        isAtTop ? "" : "bg-grey-background"
                    }`}
                >
                    <div
                        ref={navbarWrapperRef}
                        className={`bg-grey-background border-b-[1px] border-[#1B1B1B] px-4 flex justify-center items-center w-full min-h-[66px] ${
                            user && organization ? "md:px-20" : "md:px-0"
                        }`}
                    >
                        <div
                            className={`flex flex-row justify-between items-center ${
                                user && organization ? "w-full" : "w-full md:w-2/3"
                            }`}
                        >
                            {user && organization ? (
                                <Link to={`/organization/${organization["_id"]}/dashboard/explore`}>
                                    <img
                                        src={BDLogo}
                                        alt="Bharat Diffusion"
                                        className="h-8 md:h-11"
                                        draggable={false}
                                    />
                                </Link>
                            ) : (
                                <a
                                    href={`https://www.${environment.BHARAT_DIFFUSION_PLATFORM_DOMAIN}`}
                                >
                                    <img
                                        src={BDLogo}
                                        alt="Bharat Diffusion"
                                        className="h-8 md:h-11"
                                        draggable={false}
                                    />
                                </a>
                            )}

                            {user && organization && (
                                <div className="flex flex-row gap-1 items-center">
                                    <IconButton
                                        as={Link}
                                        tooltipLabel={"My Generations"}
                                        to={`/organization/${organization["_id"]}/dashboard/my-generations`}
                                        type="photoLibrary"
                                        className={{
                                            button: "rounded-full bg-grey-700",
                                            wrapper: "w-10 h-10 fill-white flex",
                                        }}
                                        onClick={() => {
                                            EventEmitter.dispatch(MyGenerationsClick, {
                                                email: user?.email,
                                                $set_once: {
                                                    email: user?.email,
                                                },
                                            });
                                        }}
                                    />
                                    <button
                                        className="md:bg-grey-800 md:active:bg-grey-700 hover:bg-grey-600 *:hover:fill-white rounded-full flex flex-row items-center md:px-0.5 md:max-w-52 md:text-nowrap h-10"
                                        onClick={toggleSettingsMenu}
                                    >
                                        {user.profilePicUrl ? (
                                            <img
                                                className="h-8 m-1 rounded-full"
                                                src={user.profilePicUrl}
                                                draggable={false}
                                            />
                                        ) : (
                                            <>
                                                <div className="hidden bg-orange-500 text-white m-1 font-semibold rounded-full h-8 w-8 text-lg md:flex flex-none items-center justify-center">
                                                    {user?.firstName[0].toUpperCase()}
                                                </div>
                                                <Icon
                                                    type="userProfile"
                                                    className={{
                                                        wrapper:
                                                            "block md:hidden w-10 h-10 fill-grey-300",
                                                    }}
                                                />
                                            </>
                                        )}
                                        <div className="hidden md:block my-2">
                                            <Icon
                                                type="chevronDown"
                                                className={{
                                                    wrapper: "fill-grey-300 flex-none rounded-full",
                                                }}
                                            />
                                        </div>
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>
                    {user && organization && (
                        <div
                            className="fixed md:absolute md:top-[66px] -bottom-1.5 md:bottom-auto left-0 w-full"
                            ref={formWrapperRef}
                        >
                            <GenerationForm
                                isAtTop={isAtTop}
                                isScrolledBeyondBounds={formRect?.bottom < 0}
                                setIsCreditLimitExceeded={setIsCreditLimitExceeded}
                            />
                        </div>
                    )}
                </div>
                <SettingsMenuModal
                    user={user}
                    isOpen={settingsIsOpen}
                    setIsOpen={setSettingsIsOpen}
                    onLogoutClick={() => setLogoutPopupIsOpen(true)}
                />
                <LogoutPopup
                    isOpen={logoutPopupIsOpen}
                    setIsOpen={setLogoutPopupIsOpen}
                    onCancel={() => setLogoutPopupIsOpen(false)}
                />
                <CreditLimitExceededPopup
                    isOpen={isCreditLimitExceeded}
                    setIsOpen={setIsCreditLimitExceeded}
                />
            </header>
            {formRect?.bottom && (
                <div
                    className="fixed bottom-14 right-4 flex justify-center items-center z-40"
                    // style={{ top: `${Math.max(formRect?.bottom, 0) + 154}px` }}
                >
                    <IconButton
                        type="arrow"
                        tooltipLabel="Back to top"
                        tabIndex={"-1"}
                        className={{
                            button: `outline-none focus:shadow-none enabled:hover:!bg-grey-200 bg-grey-white fill-grey-black w-13 h-13 rounded-full transition-scroll-top-button duration-150 ease-in-out ${
                                formRect && formRect?.bottom > 0
                                    ? "max-w-0 max-h-0"
                                    : "max-w-14 max-h-14"
                            }`,
                            wrapper: "h-13 mb-1",
                            icon: "rotate-90 stroke-grey-black",
                        }}
                        onClick={() => {
                            if (typeof window !== "undefined")
                                window.scrollTo({
                                    top: 0,
                                    behavior: "smooth",
                                });
                        }}
                    />
                </div>
            )}
        </>
    );
};

export default Navbar;
