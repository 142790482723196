import PopupWrapper from "@components/Popup/PopupWrapper";
import React from "react";
import { ContactCtaClick, PricingPopupDismissed } from "@events/events";
import { EventEmitter } from "@events/eventEmitter";
import { useSelector } from "react-redux";
import IconButton from "@components/IconButton";
import copy from "copy-to-clipboard";
import { toast } from "sonner";
import { SUPPORT_MAIL_ADDRESS } from "@common/constants/constants";

const CreditLimitExceededPopup = ({ isOpen, setIsOpen }) => {
    const [{ organization }, authDetails] = useSelector((state) => [
        state.organizationDetails,
        state.authenticationDetails,
    ]);
    return (
        <PopupWrapper
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            showCloseButton={true}
            popupDismissedEvent={PricingPopupDismissed}
        >
            <div className="px-7.5 py-6.5 flex flex-col gap-4 items-center">
                <h2 className="text-white text-center text-2xl font-semibold">
                    You have exhausted your credits!
                </h2>
                <p className="text-body-2 text-center text-lg font-normal">
                    To generate more images using Bharat Diffusion, contact us at
                    <span className="underline flex items-center justify-center gap-1">
                        <a href={`mailto:${SUPPORT_MAIL_ADDRESS}?subject=Bharat Diffusion`}>
                            {SUPPORT_MAIL_ADDRESS}
                        </a>
                        <IconButton
                            type="copy"
                            className={{ wrapper: "fill-grey-300 h-7 w-7" }}
                            onClick={() => {
                                EventEmitter.dispatch(
                                    ContactCtaClick,
                                    authDetails?.user && organization
                                        ? {
                                              email: authDetails?.user?.email,
                                              $set_once: { email: authDetails?.user?.email },
                                          }
                                        : {},
                                );
                                copy(SUPPORT_MAIL_ADDRESS, {
                                    format: "text/plain",
                                });
                                toast.success("Copied to clipboard");
                            }}
                        />
                    </span>
                </p>
            </div>
        </PopupWrapper>
    );
};

export default CreditLimitExceededPopup;
