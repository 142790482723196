export const UserSignUpSuccess = "USER_SIGN_UP_SUCCESS";
export const UserLoginSuccess = "USER_LOGIN_SUCCESS";
export const UserSignUpFailed = "USER_SIGN_UP_FAILED";
export const UserLoginFailed = "USER_LOGIN_FAILED";
export const ValidationError = "VALIDATION_ERROR";
export const EmailVerificationSuccess = "EMAIL_VERIFICATION_SUCCESS";
export const EmailVerificationFailed = "EMAIL_VERIFICATION_FAILED";
export const EmailVerificationContinue = "EMAIL_VERIFICATION_CONTINUE";

// RESEND SIGN-UP MAIL EVENTS
export const ResendEmailSuccess = "RESEND_EMAIL_SUCCESS";
export const ResendEmailFailed = "RESEND_EMAIL_FAILED";
export const ResendEmailTriggered = "RESEND_EMAIL_TRIGGERED";

export const GetStarted = "GET_STARTED"; // TODO: clicked on get started button on webflow frontend
export const SignupClick = "SIGNUP_CLICK";
export const PromptClick = "PROMPT_CLICK";
export const GenerateClick = "GENERATE_CLICK";
export const ImageGenerated = "IMAGE_GENERATED";
export const ImageGenerationFailed = "IMAGE_GENERATION_FAILED";
export const ImageDownloadClick = "IMAGE_DOWNLOAD_CLICK";
export const ImageDownloaded = "IMAGE_DOWNLOADED";
export const PromptEditClick = "PROMPT_EDIT";
export const PromptCopyClick = "PROMPT_COPY";
export const PricingPopupTriggered = "PRICING_POPUP_TRIGERRED";
export const PricingPopupDismissed = "PRICING_POPUP_DISMISSED";
export const PricingPopupClicked = "PRICING_POPUP_CLICKED";
export const ProfileClick = "PROFILE_CLICK";
export const MyGenerationsClick = "MYGEN_CLICK";
export const UpgradePlanClick = "UPGRADE_PLAN_CLICK";
export const ContactCtaClick = "CONTACT_CTA_CLICK";
